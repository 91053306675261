import React, { Component } from 'react'
import './index.css'
import imageNotFound from '../../media/image/image-not-found.jpeg'
import { Link } from 'react-router-dom'
import withRouter from '../../withRouter'
import serverInfo from '../../config'
import axios from 'axios'
import PubSub from 'pubsub-js'
import Cookies from 'universal-cookie'
import userInfo from '../../userInfo'
class ProductBrief extends Component {
    constructor(props){
        super(props)
        this.cookie = new Cookies()
        this.state={
            itemAdd:true,
            item_id:null,
            product:null,
            imageUrl:null,
            listId:null,
            width:"210px",
            isLogin:this.cookie.get('login') ? true : false,
            percentage: this.cookie.get('percentage') ? this.cookie.get('percentage') : 0,
            discountPrice: null
        }
    }
    addToCart(){
        if(this.cookie.get('login') && this.cookie.get('customerid') && this.state?.product?.stock > 0 ){
            // console.log(this.cookie.get('customerid'))
            const itemData = {
                itemData:{
                    customer_id: this.cookie.get('customerid',{doNotParse:1}),
                    item_id:this.state.item_id,
                    sku:this.state.product?.sku,
                    quantity:1,
                    item_condition:this.state.product.item_condition
                }
            }
            axios.post(`${serverInfo.baseURL}/user/cart/add`,itemData,{headers:{'customerid':serverInfo.customerid,'percentage':this.state.percentage,'authorization':serverInfo.token}}).then(
                res=>{
                    if(res.data.scode === 200){
                        // console.log('cartItems',res.data.cartItems)
                        PubSub.publish('cartItems',[...res.data.cartItems])
                        const cartItems = [...res.data.cartItems]
                        const cartItemsNumber = res.data.cartItems?.reduce((total,current)=>total+current.quantity,0)
                        const cartItemsSubtotal = res.data.cartItems?.reduce((total,current)=>{
                            if(current.item_condition === 'ScratchDent'){
                                return total+(current.personal_scratch_dent_price*current.quantity)
                            }else{
                                if(current.is_sale){
                                    return total+(current.personal_sale_price*current.quantity)
                                }else{
                                    return total+(current.personal_price*current.quantity)
                                }
                            }
                        },0)
                        this.props.navigate(`/acer/add-to-cart`,{state:{from:this.props.location.pathname,product:this.state.product,imageUrl:this.state.imageUrl,quantity:1,windowYOffset:window.pageYOffset,cartItemsNumber,cartItemsSubtotal,cartItems}})
                    }else{
                        // console.log(res.data.scode,res.data.smessage)
                        alert(res.data.smessage)
                    }
                },
                rej=>console.log(rej)
            )
        }
        
    }
    removeFromWishList=()=>{
        axios.delete(`${serverInfo.baseURL}/user/wishList/${this.state.listId}/item/${this.state.product.item_id}/${this.state.product.item_condition}/remove`,{headers:{'customerid':serverInfo.customerid,'percentage':this.state.percentage,'authorization':serverInfo.token}}).then(
            res=>{
                if(res.data.scode === 200){
                    let wishListsItems = [...userInfo.wishListsItems]
                    let index = 0
                    for(index = 0; index<wishListsItems.length; index++){
                        if(wishListsItems[index].list_id === this.state.listId && wishListsItems[index].item_id === this.state.product.item_id && wishListsItems[index].item_condition === this.state.product.item_condition){
                            wishListsItems.splice(index,1)
                            break
                        }
                    } 
                    userInfo.updateInfo('wishListsItems',wishListsItems)
                    userInfo.updateInfo('wishLists',res.data.wishLists)
                    PubSub.publish('wishListItemsUpdate',{wishListItems:res.data.wishListItems,wishLists:res.data.wishLists})
                    // PubSub.publish('wishLists',res.data.wishLists)
                }else{
                    alert(`Remove Item from Wish List FAILED, please try again.`)
                }
            },
            rej=>console.log(rej)
        )
    }
    componentDidUpdate(){
        if(this.props.product && !this.state.product){
            // console.log(this.props.product)
            const imageRegex = new RegExp(serverInfo.imageRegex, 'g')
            const googleImageId = imageRegex.exec(this.props.product.main_image)?.[1]
            let mainImageUrl = null
            if(googleImageId){
                mainImageUrl = `${serverInfo.imageUrlPrefix}${googleImageId}${serverInfo.imageUrlSurfix}`
            }else{
                console.log("product brief",this.props.product.main_image,this.props.product.zoho_id)
            }
            const item_id = this.props.product.zoho_id? this.props.product.zoho_id: this.props.product.item_id
            this.setState({product:this.props.product, imageUrl:mainImageUrl,listId:this.props.listId,item_id})
        }
        if(this.props.width && this.props.width!==this.state.width){
            this.setState({width:this.props.width})
        }
    }
    componentDidMount(){
        this.isLoginSub = PubSub.subscribe('isLogin',(_,data)=>{
            this.setState({isLogin:data})
        })
        this.percentageSub = PubSub.subscribe('percentage',(_,data)=>{
            this.setState({percentage:data})
        })
        if(this.props.product){
            // console.log(this.props.product)
            const imageRegex = new RegExp(serverInfo.imageRegex, 'g')
            const googleImageId = imageRegex.exec(this.props.product.main_image)?.[1]
            // console.log("product brief",this.props.product)
            let mainImageUrl = null
            if(googleImageId){
                mainImageUrl = `${serverInfo.imageUrlPrefix}${googleImageId}${serverInfo.imageUrlSurfix}`
            }else{
                console.log("product brief",this.props.product.main_image,this.props.product.zoho_id)
            }
            const item_id = this.props.product.zoho_id? this.props.product.zoho_id: this.props.product.item_id
            this.setState({
                product:this.props.product,
                item_id,
                imageUrl:mainImageUrl,
                listId:this.props.listId,
                width:this.props.width?this.props.width:'210px',
                discountPrice:this.props.product.item_condition === "ScratchDent" && this.props.product.personal_scratch_dent_price && this.props.product.personal_scratch_dent_price !== 0 ? this.props.product.personal_scratch_dent_price : (this.props.product.is_sale && this.props.product.personal_sale_price && this.props.product.personal_sale_price !== 0? this.props.product.personal_sale_price : null)
            })
        }
    }
    componentWillUnmount(){
        PubSub.unsubscribe(this.isLoginSub)
        PubSub.unsubscribe(this.percentageSub)
    }
    render() {
            // console.log(this.state.product?.item_condition)
        return (
        <div className='productbrief' style={{maxWidth:`${this.state.width?this.state.width:'210px'}`}}>
            <div className='productbrief-display'>
                <div className='productbrief-tag-group'>
                    <p className='productbrief-tag' style={{display:this.state?.product?.is_sale && this.state.discountPrice?"flex":"none"}}>SALE</p>
                    <p className='productbrief-tag' style={{display:this.state?.product?.item_condition === "BrandNew"?"flex":"none",backgroundColor:"rgb(177,211,136)"}}>NEW</p>
                    <p className='productbrief-tag' style={{display:this.state?.product?.item_condition === "ScratchDent" && this.state.discountPrice?"flex":"none",backgroundColor:"RED",fontSize:"8px",fontWeight:"bold"}}>SCRATCH</p>
                </div>
                <Link to={{pathname:`/acer/product/${this.state.item_id}/${this.state.product?.item_condition}`}}state={{from:this.props.location.pathname}} target='_blank'><img src={this.state.product?.is_active && this.state.imageUrl ? this.state.imageUrl : imageNotFound} alt="err" onError={(e)=>{e.target.onError = null; e.target.src=imageNotFound}}/></Link>
            </div>
            <div className='productbrief-title'>
                <Link to={{pathname:`/acer/product/${this.state.item_id}/${this.state.product?.item_condition}`}} state={{from:this.props.location.pathname}} target='_blank'>{this.state.product?.is_active ? this.state?.product?.item_name : "ITEM HAS BEEN REMOVED"} </Link>
            </div>
            <div className='productbrief-footer'>
                <div className='productbrief-price' style={{display:this.state.isLogin && this.state.product?.is_active ? "flex" : "none"}}>
                    <p>PRICE:</p>
                    <p className={(this.state?.product?.item_condition === "ScratchDent" || this.state?.product?.is_sale) && this.state.discountPrice ?'origin-price':'sell-price'}>{`$${this.state?.product?.personal_price?.toFixed(2)}`}</p>
                    <p className='sell-price' style={{display:(this.state?.product?.item_condition === "ScratchDent" || this.state?.product?.is_sale) && this.state.discountPrice?"flex":"none"}}>{`$${this.state?.product?.item_condition === "ScratchDent"?this.state.discountPrice?.toFixed(2):this.state.discountPrice?.toFixed(2)}`}</p>
                </div>
                <div className='productbrief-button'>
                {/* <Link to={{pathname:'/acer/add-to-cart/sku'}} state ={{from:this.props.location.pathname,product:this.state.product,imageUrl:this.state.imageUrl,quantity:1}} style={{width:"100%",height:"100%",maxHeight:"35px"}}></Link> */}
                    <button onClick={()=>this.addToCart()} 
                        disabled={!this.state.isLogin || !this.state.product?.is_active ? true : (this.state?.product?.item_condition === "ScratchDent" ? (this.state?.product?.scratch_dent_stock > 0 ? false : true) : (this.state?.product?.stock > 0 ? false:true))} 
                        className={!this.state.isLogin || !this.state.product?.is_active ? "disabled" : (this.state?.product?.item_condition === "ScratchDent" ? (this.state?.product?.scratch_dent_stock > 0 ? "" : "disabled") : (this.state?.product?.stock > 0 ? "":"disabled"))}
                    >
                        {!this.state.isLogin || !this.state.product?.is_active ? (!this.state.isLogin ? "Please Login" : "Item Removed") : (this.state?.product?.item_condition === "ScratchDent" ? (this.state?.product?.scratch_dent_stock > 0 ? "ADD TO CART" : "OUT OF STOCK") : (this.state?.product?.stock > 0 ? "ADD TO CART":"OUT OF STOCK"))}
                    </button>
                </div>
                <div className='productbrief-remove' style={{display:this.state.listId?'flex':'none'}}>
                    <button onClick={()=>this.removeFromWishList()}>Remove</button>
                </div>
            </div>
        </div>
        )
    }
}
export default withRouter(ProductBrief)